import { createI18n } from 'vue-i18n'

const messages = Object.fromEntries(
  Object.entries(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    import.meta.glob<{ default: any }>('./locales/*.json', { eager: true })
  ).map(([key, value]) => [key.slice(10, -5), value.default])
)

export default createI18n({
  locale: localStorage.getItem('locale') ?? 'zh_TW',
  legacy: true, // 如要支持compositionAPI，需設為false;
  globalInjection: true, // 全域註冊$t
  fallbackLocale: 'zh_TW',
  messages,
  silentTranslationWarn: true,
})
